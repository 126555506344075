<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>


<style>
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>