import { h } from "vue";
import IconBad from "./icon-bad.vue";
import IconNormal from "./icon-normal.vue";
import IconGood from "./icon-good.vue";


const App = {
  bad: IconBad,
  normal: IconNormal,
  good: IconGood
}



const customSvgNameToComponent = {
  ...App,
}

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon]),
};

export { customSVGs /* aliases */ };