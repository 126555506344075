<template>
  <v-container class="h-100">
    <v-slide-y-transition group>
      <v-card v-if="!ShowThank" variant="flat"
        class="d-flex flex-row align-center justify-center h-100 w-100"
        :style="delayed ? 'pointer-events:none' : ''" style="gap: 18px;">

        <RatingBtn :rating="1" icon="custom:good" @delayed="OnDelayed" @submitted="OnShowThank" />
        <RatingBtn :rating="0" icon="custom:normal" @delayed="OnDelayed" @submitted="OnShowThank" />
        <RatingBtn :rating="-1" icon="custom:bad" @delayed="OnDelayed" @submitted="OnShowThank" />

      </v-card>
      <v-card v-else variant="flat" class="d-flex flex-row align-center justify-center h-100 w-100">
        <div style="font-family: 'MBFont'; font-size: 81px;">Thank you for your participation</div>
      </v-card>
    </v-slide-y-transition>

  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import RatingBtn from './Rating-Btn.vue'

const delayed = ref(false)
const ShowThank = ref(false)

function OnDelayed(value) {
  delayed.value = value
}

function OnShowThank() {
  ShowThank.value = true;
  setTimeout(() => {
    ShowThank.value = false
  }, 3000);

}

</script>
