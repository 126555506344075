<template>
    <v-btn size="320" icon variant="text" color="transparent" :class="{ anim: animate }"
        @pointerdown="OnSendRating(rating)">
        <v-icon size="320" :icon="icon" />
    </v-btn>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import axios from "axios";

defineProps(['rating', 'icon'])
const emits = defineEmits(['delayed', 'submitted'])

const _axios = axios.create({ baseURL: process.env.VUE_APP_api.server });

const animate = ref(false)

async function OnSendRating(value) {

    try {
        animate.value = true
        emits('delayed', true)

        _axios.post(`send-rating?rating=${value}`)

        setTimeout(() => {
            emits('delayed', false)
            animate.value = false
            emits('submitted', true)
        }, 500);

    } catch (error) {
        console.log(error.message)
        // animate.value = false
        emits('delayed', false)
    }
}

</script>


<style>
.anim {
    animation: anim 0.37s ease;
    scale: 1
}

@keyframes anim {
    0% {
        scale: 1;
    }

    50% {
        scale: .92;
    }

    100% {
        scale: 1;
    }
}
</style>