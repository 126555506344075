import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'

import './styles/main.scss'


createApp(App).use(vuetify).use(router).mount('#app')
