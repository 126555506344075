// Vuetify

import 'vuetify/styles';
import '@/styles/settings.scss';

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { customSVGs } from '../styles/icons/custom'

export default createVuetify({
    theme: {
        themes: {
            light: {
                colors: {                    
                },
            },
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
          custom: customSVGs
        },
      },
})